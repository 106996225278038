<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config.json";
import { authComputed } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
import store from "@/state/store";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 1 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Employee Leave Accrual",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    ...authComputed,
  },
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    year: { required },
  },
  mounted() {
    //this.refreshTable();
    const currentDate = new Date();
    this.year = (parseInt(currentDate.getUTCMonth()) + 1 ) <= 9 ? currentDate.getFullYear() : currentDate.getFullYear()+1;

  },
  methods: {
    refreshTable() {
      const url = `${this.ROUTES.leaveAccrual}/employee-leave-accruals`;
      this.apiGet(url, "Get Leave Accrual Error").then((res) => {
        const { data } = res;
        //console.log(data)
        data.leave_types.forEach((l_type) => {
          let field = {
            key: `key_${l_type.leave_type_id}`,
            label: l_type.leave_name,
            sortable: true,
          };
          this.fields.push(field);
          this.newFields.push(field);
        });
        this.accruals = data.accruals;

        const data_holder = [];
        this.accruals.forEach((leave, index) => {
          let keyV = `key_${leave.leave_type.leave_type_id}`;
          let val = leave.total;
          data_holder.push({
            sn: ++index,
            [keyV]: val,
            t7: leave.employee.emp_unique_id,
            t6: leave.employee.location.location_name,
            employee_details: `${leave.employee.emp_first_name} ${leave.employee.emp_last_name}`,
            t3: leave.employee.sector.department_name,
            total_accrual:leave.total,
            ...leave,
          });
        });
        this.accruals = data_holder;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectRow(row) {
      row = row[0];
      this.leaYear = row.lea_year;
      this.empId = row.lea_emp_id;
      this.$router.push({
        name: "employee-leave-accruals-details",
        params: { year: this.leaYear, empId: this.empId },
      });
    },
    getLeaveAccruals() {
      this.loader = true;
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.apiFormHandler("Invalid Year Entry");
      } else {
        const data = {
          year: `FY${this.year}`,
        };
        this.apiPost(`${this.ROUTES.leaveAccrual}/year`, data, "Leave Accrual Error").then(
          (res) => {
            this.loader = false;
            this.result = true;
            const { data } = res;
            //console.log(data);
            this.accruals = data.accruals;
            //console.log(this.accruals);

            const data_holder = [];
            data.forEach((leave, index) => {
              //console.log(leave.emp_first_name)
              //let keyV = `key_${leave.leave_type.leave_type_id}`;
              //let val = leave.total;
              data_holder.push({
                sn: ++index,
                //[keyV]: val,
                t7: leave.t7,
                t6: leave.t6,
                employee_details: `${leave.emp_first_name} ${leave.emp_last_name}`,
                t3: leave.t3,
                total_accrual: leave.total,
                ...leave,
              });
            });
            this.accruals = data_holder;
          }
        );
      }
    },
  },
  data() {
    return {
      title: " Employee Leave Accrual",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: " Employee Leave Accrual",
          active: true,
        },
      ],
      leave_types: [],
      emp_leave_types: [],
      accruals: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      loader: false,
      sortBy: "sn",
      sortDesc: false,
      newFields: [],
      fields: [
        { key: "sn", label: "S/n", sortable: true },
        { key: "t7", label: "T7", sortable: true },
        { key: "employee_details", label: "Name", sortable: true },
        { key: "t6", label: "T6", sortable: true },
        { key: "t3", label: "T3", sortable: true },
        { key: "total_accrual", label: "Total Accrual", sortable: true },
      ],
      leaveAppID: null,
      leaYear: null,
      empId: null,
      year: null,
      result: false,
      submitted: false,
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <scale-loader v-if="loader" />
    <div v-else class="row">
      <div class="card">
        <div class="card-body">
          <div class="col-md-12">
            <form>
              <div class="form-group">
                <label for="">Fiscal Year</label>
                <input min="1900" v-model="year" :class="{
              'is-invalid': submitted && $v.year.$error,
            }" max="2099" step="1" value="2022" class="form-control">
              </div>
              <div class="form-group float-right">
                <button class="btn btn-primary" @click.prevent="getLeaveAccruals">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="result">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="donor-table"
                bordered
                selectable
                hover
                :items="accruals"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"
                @row-selected="selectRow"
              >
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
